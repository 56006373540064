<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('create', resource)">
            <b-button variant="primary" :to="{ name: `create-${route}` }">{{
              $t("button.create")
            }}</b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          @sort-changed="sortChanged"
          @destroy="destroy"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :deletable="true"
          :resource="resource"
          :route="route"
          primary-field="nameEn"
        >
          <template #cell(status)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.isEnable"
            >
              {{ $t(`field.active`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`field.inactive`) }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                v-if="$can('update', resource)"
                :to="{
                  name: `update-${route}`,
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('button.update')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="$can('delete', resource)"
                v-b-tooltip.hover
                :title="$t('button.delete')"
                variant="danger"
                pill
                size="sm"
                @click="
                  $refs.table.destroy(data.item.id, data.index, data.item)
                "
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>
        
<script>
import { BCard, BRow, BCol, BButton, BAvatar, BBadge } from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import { avatarText } from "@core/utils/filter";

const CompanyRepository = Repository.get("company");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BBadge,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
  },
  watch: {
    perPage() {
      this.list();
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        nameEn: this.$route.query.nameEn || null,
        nameKm: this.$route.query.nameKm || null,
        isEnable: this.$route.query.isEnable || null,
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        nameEn: this.$route.query.nameEn || null,
        nameKm: this.$route.query.nameKm || null,
        isEnable: this.$route.query.isEnable || null,
      },
      avatarText,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.query });
    },
    list(page = 1) {
      this.query.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.query.order = sortBy;
      this.query.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.query,
        searchFields: this.searchFields,
      };
      CompanyRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    destroy(id) {
      CompanyRepository.destroy(id)
        .then((response) => {
          this.getData();
        })
        .catch((error) => {});
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const resource = "company";
    const route = "company";

    return { fields, searchFields, resource, route };
  },
};
</script>